<template>
  <section id="check">
    <div class="checkout_wrap">
      <div class="tabmenu tabmenu2">
        <ul>
          <li @click="onClickGoPayPage">
            <a>{{ $t("main.usageHistory") }}</a>
          </li>
          <li>
            <a class="active">{{ $t("main.paymentHistory") }}</a>
          </li>
        </ul>
      </div>
      <div
        v-for="(item, index) in myPaidList"
        :key="index"
        :class="['pay_list_wrap', index === 0 && 'mg_t40']"
      >
        <div :class="['pay_title', isCanceled(item) && 'remainder']">
          {{ $t(`common.${isCanceled(item) ? "cancelPayment" : "payment"}`) }}
          <span class="date">
            {{ approvalDate(item.approvalDt) }}
          </span>
        </div>
        <dl class="pay_list">
          <dt>{{ $t("main.paymentAmount") }}</dt>
          <dd :class="[isCanceled(item) && 'c_r']">
            {{ $t("common.won", [numberWithCommas(item.approvalAmt)]) }}
          </dd>
          <dt>{{ $t("main.paymentMethod") }}</dt>
          <dd>{{ $t("pay.creditPrefix", [item.purcName]) }}</dd>
          <dt>{{ $t("main.paymentWay") }}</dt>
          <dd>{{ $t("main.creditCardPayment") }}</dd>
        </dl>
        <div v-if="!isCanceled(item)" class="btn_cancel_wrap">
          <a class="btn_receipt">{{ $t("common.receipt") }}</a>
          <a class="btn_pay_cancel">{{ $t("common.cancelPay") }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import dayjs from "dayjs";
import checkOutMixin from "@/mixins/checkOutMixin";

export default {
  name: "PayList",
  mixins: [checkOutMixin],
  async created() {
    await this.fetch("pay-list");
  },
  methods: {
    isCanceled(item) {
      return item.approvalDiv === "T_CANCEL";
    },
    approvalDate(datetime) {
      return dayjs(datetime).format("YYYY. MM. DD (ddd) HH:mm:ss");
    },
  },
};
</script>
