import { numberWithCommas } from "@/utils/number";
import ProjectService from "@/service/ProjectService";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      tgPayMsts: [],
      tgSalesDtls: [],
      classCode1Options: ["FEE", "CART"],
    };
  },
  computed: {
    ...mapGetters(["payerName"]),
    totAmt() {
      return this.myPayList?.reduce((acc, cur) => acc + cur.totAmt, 0);
    },
    paidAmt() {
      return this.tgPayMsts?.reduce((acc, cur) => acc + cur.payAmt, 0);
    },
    remainAmt() {
      const remainder = this.totAmt - this.paidAmt;
      return remainder > 0 ? remainder : 0;
    },
    myPayList() {
      return this.tgSalesDtls?.filter((item) =>
        this.classCode1Options.includes(item.classCode1)
      );
    },
    myPaidList() {
      let paidList = [];
      this.tgPayMsts?.forEach((item) => {
        if (item.tgPayCards) {
          const hasCanceled = item.tgPayCards
            ?.map((c) => c.approvalDiv)
            ?.includes("T_CANCEL");
          if (hasCanceled) {
            item.tgPayCards
              ?.filter((c) => c.approvalDiv === "T_CANCEL")
              ?.forEach((c) => paidList.push(c));
          } else {
            item.tgPayCards?.forEach((c) => paidList.push(c));
          }
        }
      });
      return paidList.reverse();
    },
  },
  methods: {
    numberWithCommas,
    takeAmtByClassCode(classCode = "FEE") {
      return this.myPayList
        ?.filter((item) => item.classCode1 === classCode)
        ?.reduce((acc, cur) => acc + cur.totAmt, 0);
    },
    async onClickGoPayPage() {
      await this.$router.push("/check-out/pay");
    },
    async onClickGoPayMyPage() {
      await this.$router.push("/check-out/pay-my");
    },
    async onClickGoPayListPage() {
      await this.$router.push("/check-out/pay-list");
    },
    async fetch(tab = "pay") {
      if (tab === "pay") {
        const { tgPayMsts, tgSalesDtls } =
          await ProjectService.fetchUserPaymentHistoryPreUsed();
        this.tgPayMsts = tgPayMsts;
        this.tgSalesDtls = tgSalesDtls;
      } else {
        this.tgPayMsts = await ProjectService.fetchUserPaymentHistoryPaid();
      }
    },
  },
};
